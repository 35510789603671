
/* MyComponentStyles.css */
.imageWrapper22{
  position: 'relative'  !important;
  display: 'inline-block' !important;
  width: '100%'  !important;
  background-color: #0e1c5f  !important;
  font-size: 1rem  !important; /* Smaller text on mobile devices */
}


@media (max-width: 768px) {
  .textStyle {
    font-size: 1rem !important; /* Smaller text on mobile devices */
  }
  
  .iconStyle {
    font-size: 20px !important; /* Smaller icons on mobile devices */
  }
}
.imageWrapper{
  position: 'relative' !important;
  display: 'inline-block' !important;
  width: '100%' !important;
  background-color: #0e1c5f !important;
}

.imageFullWidth {
  width: '100%';
  display: 'block';
}

.overlayContent {
  position: 'absolute';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  color: 'black';
  width: 'calc(100% - 20px)';
  display: 'flex';
  flexDirection: 'column';
  justifyContent: 'space-around';
}

.ulStyle {
  listStyle: 'none';
  padding: 0;
  margin: 0;
}

.liStyle {
  display: 'flex';
  alignItems: 'center';
  marginBottom: '25px'; /* Increased line separation */
  color: 'black';
  textDecoration: 'none';
}

.iconStyle {
  marginRight: '8px';
  fontSize: '24px';
}

.textStyle {
  fontSize: '1.25rem';
}