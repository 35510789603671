@import "../../_globalColor";

img {
  width: 100%;  
  height: auto;  
}
.greet-main1 {
  width: 90% !important;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}