@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.textarea-responsive {
  font-size: 14px !important; /* Smaller font size for mobile devices */
  width: 100%; /* Responsive width */
  padding: 10px; /* Adequate padding for typing comfort */
  box-sizing: border-box; /* Includes padding in the width */
}

/* Media query for devices wider than 768px (tablets and desktops) */
@media (min-width: 768px) {
  .textarea-responsive {
    font-size: 16px !important; /* Slightly larger font size for readability on larger screens */
  }
}


:root {
  --light: #d0d1d3;
  --dark: null;
  --green: #17619a;
}

body {
  font-family: Poppins;
  background: var(--dark);
  line-height: 26px;
  margin: 0;
}

h1 {
  color: var(--green);
}

.App {
  height: 75vh;
  margin-top: 15vh;
}

form {
  margin-top: 20px;
  background: var(--dark);
}

.formRow {
  margin: 10px 0 20px 0;
}

.formInput {
  padding: 15px 10px;
  background-color: var(--dark);
  box-shadow: 0 0 0 2px var(--green);
  border: none;
  color: var(--green);
}

.formInput:focus {
  background-color: var(--dark);
  outline: none;
  box-shadow: 0 0 0 2px var(--green);
  color: var(--light);
}

.formInput::placeholder {
  color: var(--green);
  opacity: 1;
}

.formInput:focus::placeholder {
  color: var(--light);
  opacity: 1;
}

.errorMessage {
  color: #f45532;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

#notifyToast {
  color: #fff;
  border-radius: 6px;
  width: 200px;
  margin-left: 75px;
  background-color: var(--green);
}

.submit-btn {
  background-color: var(--dark);
  color: rgb(15, 31, 101) !important;
  padding: 15px 50px;
  border: 2px solid var(--green);
  border-radius: 10px;
  margin-bottom: 25px;
}

.submit-btn:hover {
  background-color: var(--green);
  color: var(--dark) !important;
  border: 2px solid var(--green);
}

.submit-btn:disabled:hover {
  background-color: var(--dark);
  color: var(--green) !important;
  cursor: wait;
}

@media (max-width: 768px) {
  .submit-message {
    width: 125px;
    margin-left: 200px;
  }
}






*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins" , sans-serif;
}
.body1{
  min-height: 100vh;
  width: 100%;
  background: #c8e8e9;
  display: flex;
  align-items: center;
  justify-content: center;
}


.content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content .left-side{
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
  gap: 50px;
}
.content .left-side::before{
  content: '';
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #afafb6;
}
.content .left-side .details{
  margin: 14px;
  text-align: center;
}
.content .left-side .details i{
  font-size: 30px;
  color: #0099DB;
  margin-bottom: 10px;
}
.content .left-side .details .topic{
  font-size: 18px;
  font-weight: 500;
}
.content .left-side .details .text-one,
.content .left-side .details .text-two{
  font-size: 14px;
  color: #afafb6;
}
.content .right-side{
  width: 75%;
  margin-left: 75px;
}

@media (min-width: 550px) {
  .content .right-side .topic-text{
    font-size: 23px;
    font-weight: 600;
    color: #0099DB;
  }
}
@media (max-width: 550px) {
  .content .right-side .topic-text{
    font-size: 15px;
    font-weight: 600;
    color: #0099DB;
  }
}
.select{
  width: 100% !important; 
  border: none !important;
  outline: none !important;
  font-size: 26px !important;
  background: #F0F1F8 !important;
  border-radius: 6px !important;
  padding: 0 15px !important;
  resize: none !important;}

.right-side .input-box{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center; /* This centers the items vertically */
}

.h6_1{
  display: block; /* Makes the element a block-level element */
  width: fit-content; /* Sets the width to fit its content */
  margin: auto !important; /* Centers the element horizontally */
}
.h6_2{
  display: block; /* Makes the element a block-level element */
  width: fit-content; /* Sets the width to fit its content */
  text-align: left !important;
}

.right-side .input-box input,
.right-side .input-box textarea{
  height: 90px !important;
  width: 100% !important; 
  border: none !important;
  outline: none !important;
  font-size: 36px !important;
  background: #F0F1F8 !important;
  border-radius: 6px !important;
  padding: 0 15px !important;
  resize: none !important;
}
.right-side .message-box{
  min-height: 110px;
}
.right-side .input-box textarea{
  padding-top: 6px;
}
.right-side .button{
  display: inline-block;
  margin-top: 12px;
}
.right-side .button input[type="button"]{
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #3e2093;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button input[type="button"]:hover{
  background: #5029bc;
}



@media (min-width: 550px) {
  .container1{
    width: 95%;
    background: #fff;
    border-radius: 6px;
    padding: 20px 60px 30px 40px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 750px) {
  .container1{
    width: 90%;
    padding: 30px 40px 40px 35px ;
  }
  .container1 .content .right-side{
   width: 75%;
   margin-left: 55px;
}
}
@media (max-width: 820px) {
  .container1{
    margin: 10px 0;
    height: 100%;
  }
  .container1 .content{
    flex-direction: column-reverse;
  }
 .container1 .content .left-side{
   width: 100%;
   flex-direction: row;
   justify-content: center;
   flex-wrap: wrap;
 }
 .container1 .content .left-side::before{
   display: none;
 }
 .container1 .content .right-side{
   width: 100%;
   margin-left: 0;
 }
}

.lg_back {

  position: relative;
  background: url(../../assets/images/cover3.png) no-repeat center center #444;
  background-size: cover;
  color: #fff;
  clear: both;
}