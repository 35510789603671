
.img_scroll_div {
  
  //display:block;
  //
  //display:block
  
}
  .img_scroll {
    //
    width: 100%;
    height: 100%;
    display:block;
    position: relative;
    margin: auto;
  
    background-position:center;
  
    
  }
  .img_scroll_backup {
    //
    //height: 615px;
    //height: 100%;
    display:block;
    position: relative;
    margin: auto;
    max-height: 615px;
    background-position:center;
    background-size:cover;
    
  }
