


p{
  color: #9fa13c !important;

}

.impressumContainer {
  text-align: center;
}

.impressumContainer h1, .impressumContainer h2 {
  font-size: 2em;
  color: purple;
  font-weight: bold;
  margin-bottom: 0.5em; /* Added space below headers */
}

.impressumContainer p, .impressumContainer div {
  font-size: 1.2em;
  color: black;
  margin: 1em auto; /* Increased space around paragraphs for better separation */
  line-height: 1.5; /* Increased line height for better readability */
}

.subTitle {
  font-weight: bold;
  color: purple;
  display: block; /* Make subtitle start on a new line */
  margin: 1em 0; /* Add space around subtitles */
}

a {
  color: blue;
  text-decoration: underline;
  font-weight: bold !important;
}