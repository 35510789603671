@import "../../_globalColor";

.subheading{

  color: #adb356 ;


}


img {
  width: 100%;  
  height: auto;  
}
h2 {
 
  box-shadow: 0px 10px 30px -18px #ffffff;
  color: #abb434 ;

}
h3 {
  text-align: center; /* Centers text horizontally within the div */
 
  color: #937b2d !important;

}

h5 {
 
  text-align: center; /* Centers text horizontally within the div */

  color: #b2ad17 !important;

}
h3:hover,h5:hover {
  text-align: center; /* Centers text horizontally within the div */
  transform: scale(1.1); /* Scales up by 10% */
  color: #937b2d !important;
}





p {
 

  color: white !important;

}
