@import "../../_globalColor";

@media (min-width: 768px) {
  .m-8 {
    margin: 2rem; /* You can adjust the value as needed */
  }
}
.m9-9 {
  margin: 10rem; /* You can adjust the value as needed */
}
.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}
.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}

.greeting-main {
  display: flex;
}

.subTitle {
  color: $subTitle !important;
}

.greeting-main {
  display: flex;
}

.greeting-main > * {
  flex: 1;
  margin-bottom: 30px;
}
.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

.greeting-text {
  font-size: 70px;
  line-height: 1.1;
  color: $textColor !important;
}

.greeting-text-p {
  font-size: 30px;
  line-height: 40px;
}

.greeting-image-div > img {
  max-width: 100%;
  height: auto;
}

.wave-emoji {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  .greeting-main {
    display: block;
  }
  .greeting-image-div {
    height: 256px;
  }
}

@media (max-width: 320px) {
  .main {
    width: auto;
  }
}
.css-typing p {
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  position: relative;
}

.css-typing p::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-right: .15em solid black; /* Simulates the cursor */
  animation: blink-caret .75s step-end infinite;
}

.css-typing p:first-child {
  animation: type 2s steps(40, end) forwards;
}

.css-typing p:nth-child(2) {
  /* Starts typing after the first paragraph finishes */
  animation: type 2s steps(40, end) 2s forwards;
}

.css-typing p:nth-child(3) {
  /* Starts typing after the second paragraph finishes */
  animation: type 2s steps(40, end) 4s forwards;
}

.css-typing p:not(:last-child)::after {
  /* Hides the cursor immediately when the typing animation is complete for all but the last paragraph */
  animation: none;
}

/* The typing effect */
@keyframes type {
  from { width: 0; }
  to { 
    width: 100%; 
    border-right: none; /* Hides the cursor once typing is done */
  }
}

/* The cursor blink effect */
@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}
@media (max-width: 768px) {
  .css-typing p {
    font-size: 65% !important; /* Adjusts the font size to 65% of its original size on mobile devices */
  }
}
//////
/// 
