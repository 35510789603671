.card-img-top-logo2{
    height: 	40px;
    margin-bottom: 11px;
  }
.facts-text3 { 
    font-size: 2.5rem;
display: inline;
color: rgb(188, 179, 194);
  opacity: 7;
  font-family: Garamond, serif;
    }
.facts-text2 { 
   
  color: rgb(188, 179, 194);
  opacity: 7;
  font-family: Garamond, serif;
  }
.facts-container {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #003267;
      opacity: 0.8;
      
    }
.facts-icon {
      font-size: 2.5rem;
      color: #248eff;
    }
.facts-counter {
      font-size: 2.5rem;
      font-weight: 700;
      z-index: 3;
    }
.facts-title {

  font-size: 9rem !important;
    }
  }




