@import "../../_globalColor";
*{

  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

img {
  width: 100%;  
  height: auto;  
}
.flex_j {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3
}

.custom-e {
  position: relative;
  border: 2px solid #0d6efd; /* Border with 'border-info' color (#0d6efd) */
  border-radius: 50%; /* Rounded-circle */
  background: linear-gradient(45deg, #16b3c2, #0e1c5f);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.4rem;
  /* Additional styles if needed */
}
.flex_backg{
background: linear-gradient(45deg, #f8f9fa, #d6d8db);
opacity: 0.5;
display: block;
} /* Gradient background with 'bg-gradient' and 'bg-secondary' colors */
//
//.custom-e:hover {
   /* Change the gradient colors on hover */
//  background: linear-gradient(45deg, #094786, #321264); /* Gradient background with 'bg-gradient' and 'bg-secondary' colors */
 // opacity: 0.9; /* Change the opacity on hover */
//}
.image-text-container {
  position: relative;
  text-align: center;
}

.tab-image {
  width: 100%; /* Adjust if needed */
  display: block; /* Removes bottom margin/space under the image */
}

.overlay-text {
  position: absolute;
  top: 10%; /* Adjust based on where you want the text */
  left: 0;
  width: 100%;
  color: white; /* Text color */
  font-size: 20px; /* Adjust text size as needed */
}

/* MyComponentStyles.css */
@media (max-width: 768px) {
  .textStyle {
    font-size: 1rem !important; /* Smaller text on mobile devices */
  }
  
  .iconStyle {
    font-size: 20px !important; /* Smaller icons on mobile devices */
  }
}
